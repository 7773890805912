<template>
  <div class="pagination-container">
    <el-pagination
      background
      layout="sizes, prev, pager, next, jumper"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleRefresh"
      @current-change="handleRefresh">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
    pageData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      pageSizes: [10, 20, 50, 100]
    }
  },
  computed: {
    currentPage: {
      set (val) {
        this.$emit('handleQuery', {
          page: val
        })
      },
      get () {
        return this.query.page
      }
    },
    pageSize: {
      set (val) {
        this.$emit('handleQuery', {
          page: 1,
          pageSize: val
        })
      },
      get () {
        return this.query.pageSize
      }
    },
    total: {
      get () {
        return this.pageData.count
      }
    }
  },
  methods: {
    handleRefresh () {
      this.$emit('handleRefresh')
    }
  },
  watch: {
    query () {},
    pageData () {}
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
