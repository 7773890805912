import request from '@/utils/axios'

export function statisticsLoginList (params) {
  return request({
    url: `/api/console/statistics/user_login`,
    method: 'get',
    params: params
  })
}

export function statisticsRetentionList (params) {
  return request({
    url: `/api/console/statistics/user_retention`,
    method: 'get',
    params: params
  })
}