<template>
  <div>
    <el-table
      :data="tableData">
      <el-table-column
        prop="report_date"
        label="日期"
        width="150"
        align="center" />
      <el-table-column
        label="新增会员"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ scope.row.new_registrants || 0 }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="daily_active"
        label="每日活跃用户"
        min-width="150"
        align="center" /> -->
      <!-- <el-table-column
        prop="week_active"
        label="周活跃用户"
        min-width="150"
        align="center" /> -->
      <!-- <el-table-column
        prop="month_active"
        label="月活跃用户"
        min-width="150"
        align="center" /> -->
      <el-table-column
        prop="day_retention"
        label="次日留存数"
        min-width="150"
        align="center" />
      <el-table-column
        prop="week_retention"
        label="七日留存数"
        min-width="150"
        align="center" />
      <el-table-column
        prop="month_retention"
        label="月留存数"
        min-width="150"
        align="center" />
      <el-table-column
        label="次日留存率（％）"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ (scope.row.day_retention_rate * 100).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        label="七日留存率（％）"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ (scope.row.week_retention_rate * 100).toFixed(2) }}
        </template>
      </el-table-column>
      <el-table-column
        label="月留存率（％）"
        min-width="150"
        align="center">
        <template slot-scope="scope">
          {{ (scope.row.month_retention_rate * 100).toFixed(2) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
  .button-box{
    text-align: left;
    margin: 20px 0 10px;
  }
  .table-richtext-col{
    max-height: 140px;
    overflow-y: scroll;
    transform: scale(0.85);
  }
</style>
