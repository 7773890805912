<template>
  <div>
    <Head
      :to="query.to"
      :from="query.from"
      :by="query.by"
      @handleQuery="handleQuery"
      @handleRefresh="handleRefresh" />
    <Table
      :tableData="pageData.data"
      @handleRefresh="handleRefresh" />
    <Pagination
      :query="query"
      :pageData="pageData"
      @handleQuery="handleQuery"
      @handleRefresh="handleRefresh" />
  </div>
</template>

<script>
import { forIn, has } from 'lodash'
import resStatus from '@/constants/resStatus'
import { statisticsRetentionList } from '@/api/admin/statistics'

import Head from './Head'
import Table from './Table'
import Pagination from '@/components/admin/pagination'

export default {
  components: {
    Head,
    Table,
    Pagination
  },
  data () {
    return {
      query: {
        page: 1,
        pageSize: 10,
        to: '',
        from: ''
      },
      pageData: {
        data: []
      },
    }
  },
  methods: {
    init () {
      statisticsRetentionList(this.query).then(res => {
      switch (res.status) {
        case resStatus.OK:
          this.pageData = res
          break
        default:
          this.$message.error(res.message)
      }
      }).catch(err => {
        this.$message.error(err)
      })
    },
    handleQuery (obj) {
      forIn(obj, (val, key) => {
        if (has(this.query, key)) {
          this.query[key] = val
        }
      })
    },
    handleRefresh () {
      this.init()
    }
  },
  created () {
    this.init()
  },
  watch: {
    $route (to, from) {
      this.init()
    }
  }
}
</script>
